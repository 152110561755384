import React from 'react';
import DeviceOverviewMaterial from './DeviceOverviewMaterial';
import GridListTile from '@material-ui/core/GridListTile';
import GridList from '@material-ui/core/GridList';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    backgroundColor: '#0088b9',
  },
  gridList: {
    width: '100%',
    padding: 20,
  },
  tile: {
    height: "100vh",
    // margin: 20,
  }
});



class Devices extends React.Component {

  render() {
    const getGridListCols = () => {
        if (isWidthUp('xl', this.props.width)) {
          return 4;
        }

        if (isWidthUp('lg', this.props.width)) {
          return 3;
        }

        if (isWidthUp('md', this.props.width)) {
          return 2;
        }

        return 1;
      }
    const { classes } = this.props;
    return(
      <div className={classes.root} width={1}>
        <GridList cellHeight='auto' className={classes.gridList} cols={getGridListCols} spacing={20}>
            <GridListTile className={classes.tile} key="359850097141183" cols="1">
              <DeviceOverviewMaterial deviceId="359850097141183" />
            </GridListTile>
            <GridListTile className={classes.tile} key="359850097069384" cols="1">
              <DeviceOverviewMaterial deviceId="359850097069384" />
            </GridListTile>
            <GridListTile className={classes.tile} key="359850096701763" cols="1">
              <DeviceOverviewMaterial deviceId="359850096701763" />
            </GridListTile>
            <GridListTile className={classes.tile} key="359850097069822" cols="1">
              <DeviceOverviewMaterial deviceId="359850097069822" />
            </GridListTile>
            <GridListTile className={classes.tile} key="macbook" cols="1">
              <DeviceOverviewMaterial deviceId="macbook" />
            </GridListTile>
            <GridListTile className={classes.tile} key="captain_snap_server" cols="1">
              <DeviceOverviewMaterial deviceId="captain_snap_server" />
            </GridListTile>

        </GridList>
      </div>);
  }
}

export default withStyles(styles)(withWidth()(Devices));
