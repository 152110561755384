import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#339fc7",
    color: theme.palette.common.black,
  },
  body: {
    aligns: "right",
    fontSize: 14,
  },
}))(TableCell);

class DeviceOverviewMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.classes = {
      status_element: {
        margin: "30px",
      },
      card: {
        minWidth: 300,
      },
      title: {
        fontSize: 14,
      },
    };
    this.state = {
      deviceId: props.deviceId,
      status: [],
    };
  }

  componentDidMount() {
    this.fetchStatus()
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }

  fetchStatus() {
    fetch(`https://octopus.captainsnap.com/api/get_status/` + this.state.deviceId, {crossDomain:true})
    .then(response => response.json())
    .then(
      data => {
        this.setState({status: data});
        console.log(JSON.stringify(data))
      }


    )
    // If we catch errors instead of a response, let's update the app
    .catch(error => this.setState({ error, isLoading: false }));
}

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.fetchStatus()
  }

  render() {
    return (
      <Card className={this.classes.card}>
        <CardContent>

          <Typography className={this.classes.title} color="textSecondary" gutterBottom>
            Device {this.state.deviceId}
          </Typography>
          {Object.keys(this.state.status).map( key => (
            <StatusElement name={key} value={this.state.status[key]} />
          )
        )}
        </CardContent>
      </Card>
    );
  }
}

function StatusElement(props) {
  return (
    <Box marginTop={1} boxShadow={1}>
      <Table size="small" border={0}>
        <TableHead >
          <TableRow>
            <StyledTableCell><Typography variant="h6" align="center">{props.name}</Typography></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell><Typography align="right">{props.value}</Typography></StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

export default DeviceOverviewMaterial;
