import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Devices from './Devices';
import Login from './Login';

class AppRouter extends React.Component {
  render() {
    return (
      <Router>
        <Route path="/" exact component={Devices} />
        <Route path="/login/" exact component={Login} />
      </Router>
    );
  }
}

export default AppRouter;
